import {CohesiiContentComponent} from "../../lib";

const Banner = ({content}) => {

    const {strapline, title, classname = "", _uid, "additional-content": additional} = content || {}

    const imageData = {
        data: {...content, classname: 'self-center'},
        type: 'image',
    }

    const titleData = {
        data: {
            text: title
        },
        type: 'title'
    }

    return (
        <section id={`${_uid || ""} `} className={`banner row ${classname || ""}`.trim()}>
            <div className={'container mx-auto row'}>
                    <div className={'column py-[20px]'}>
                        <div className={'title-container'}>
                            {strapline && (<p className={'strapline'}>
                                {strapline }
                            </p>)}
                            <CohesiiContentComponent content={titleData}/>
                        </div>

                        <div className={'content'} dangerouslySetInnerHTML={{__html: content.content}} />

                        <div className={'additional'}>
                            {additional.map((nested, index) => (
                                <CohesiiContentComponent key={nested.data._uid || index} content={nested} />
                            ))}
                        </div>

                    </div>
                    <div className={'column'}>
                        <CohesiiContentComponent content={imageData}/>
                    </div>
                </div>
        </section>
)



}

export default Banner