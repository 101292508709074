import {CohesiiContentComponent} from "../../lib";

const BannerWithContent = ({content}) => {

    const {strapline, title, classname = "", _uid, content_area} = content || {}


    const titleData = {
        data: {
            text: title
        },
        type: 'title'
    }

    return (
        <section id={`${_uid || ""} `} className={`banner banner-content row ${classname || ""}`.trim()}>
            <div className={'container mx-auto row'}>
                    <div className={'column py-[20px]'}>
                        <div className={'title-container'}>
                            {strapline && (<p className={'strapline'}>
                                {strapline }
                            </p>)}
                            <CohesiiContentComponent content={titleData}/>
                        </div>

                        <div className={'content'} dangerouslySetInnerHTML={{__html: content.content}} />



                    </div>
                <div className={'column'}>
                    <div className={'additional'}>
                        {content_area.map((nested, index) => (
                            <CohesiiContentComponent key={nested.data._uid || index} content={nested}/>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )


}

export default BannerWithContent