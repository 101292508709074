import {useState} from "react";
import InputGroupRow from "../../Form/InputGroupRow";
import TextInput from "../../Form/TextInput";
import PhoneInputWrapper, {isPhoneValid} from "../../Form/PhoneInput";
import MultiSelect from "../../Form/MultiSelect";
import {spinner} from "../Newsletter/Form";
import TextArea from "../../Form/TextArea";
import Checkbox from "../../Form/Checkbox";
import {useContactMutation} from "../../../app/services/marketingApi";

const ContactForm = () => {

    const [contact, {isLoading}] = useContactMutation()

    const [formComplete, setFormComplete] = useState(false)
    const [validating, setValidating] = useState(false);
    const [data, setData] = useState({
            'first_name': '',
            'phone_number': '',
            'ip_address': '',
            'last_name': '',
            'company': '',
            'message': '',
            'email': '',
            'discussion_points': [],
            'honeypot': '',
            'user_id':null,
            'newsletter': 'no'
    });

    const [baseDataError, setBaseDataErrors] = useState({
        'first_name': false,
        'last_name': false,
        'company': false,
        'message': false,
        'discussion_points': false,
        'email': false,
        'newsletter': false,
        'honeypot': false
    });

    const isValidPhoneNumber = isPhoneValid(data?.phone_number || '');

    const validateField = (fieldName, value) => {
        let error = false;
        switch (fieldName) {
            case 'first_name':
                error = !value || value.length <= 2;
                break;
            case 'last_name':
                error = !value ||  value.length <= 2;
                break;
            case 'phone_number':
                error = !isValidPhoneNumber;
                break;
            case 'message':
                error = !value ||  value.length <= 2;
                break;
            case 'email':
                error = !value || !/\S+@\S+\.\S+/.test(value);
                break;
            case 'honeypot':
                error = !!value;
                break;
            default:
                break;
        }
        setBaseDataErrors(prevErrors => ({ ...prevErrors, [fieldName]: error }));
        return error;
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value

        }));
    };


    const process = () => {
        setValidating(true)
        const errors = {
            first_name: validateField('first_name', data.first_name),
            last_name: validateField('last_name', data.last_name),
            email: validateField('email', data.email),
            phone_number: validateField('phone_number', data.phone_number),
            message: validateField('message', data.message),
            honeypot: validateField('honeypot', data.honeypot),
        };

        if(!Object.values(errors).some(error => error)) {
            // process the form here using redux rtk
            contact(data)

            setValidating(false)
            setFormComplete(true)
        }else {
            setValidating(false)
        }
    }

    const newsletterLabel = "I would like Cohesii to email me exciting news,  products and services. You can unsubscribe at any time by using the link at the end of each email."

    return (
        <fieldset className={'column form !gap-0 !flex-0'}>
            <TextInput name="honeypot"
                       type={'hidden'}
                       label={"Honeypot"}
                       error={baseDataError.honeypot}
                       required={true}
                       showLabel={false}
                       value={data.honeypot}
                       handleInputChange={handleInputChange}
                       validateFieldChange={validateField}
            />
            <InputGroupRow>
                <TextInput name="first_name"
                           label={"First Name"}
                           error={baseDataError.first_name}
                           placeholder={"First Name"}
                           required={true}
                           showLabel={true}
                           value={data.first_name}
                           handleInputChange={handleInputChange}
                           validateFieldChange={validateField}
                />
                <TextInput name="last_name"
                           label={"Last Name"}
                           error={baseDataError.last_name}
                           placeholder={"Last Name"}
                           required={true}
                           showLabel={true}
                           value={data.last_name}
                           handleInputChange={handleInputChange}
                           validateFieldChange={validateField}
                />
            </InputGroupRow>
            <TextInput name="company"
                       label={"Company"}
                       error={baseDataError.company}
                       placeholder={"Company Name"}
                       required={false}
                       showLabel={true}
                       value={data.company}
                       handleInputChange={handleInputChange}
                       validateFieldChange={validateField}
            />
            <InputGroupRow>
                <TextInput name="email"
                           type={'email'}
                           label={"Email"}
                           error={baseDataError.email}
                           placeholder={"Email"}
                           required={true}
                           showLabel={true}
                           value={data.email}
                           handleInputChange={handleInputChange}
                           validateFieldChange={validateField}
                />
                <PhoneInputWrapper handleInputChange={handleInputChange}
                                   validateFieldChange={validateField}
                                   showLabel={true}
                                   name={"phone_number"}
                                   required={true}
                                   errorMessage={"Phone number is required"}
                                   value={data.phone_number || ""}
                                   error={baseDataError.phone_number}/>
            </InputGroupRow>

            <MultiSelect required={true}
                         name={'discussion_points'}
                         showLabel={true}
                         defaultval={''}
                         options={['Listing Office Space', 'Listing Specially space', 'Support', 'Investment', 'How to book space']}
                         label={'What do you want to discuss with us?'}
                         handleInputChange={handleInputChange}
            />

            <TextArea name="message"
                      label={"Message"}
                      error={baseDataError.message}
                      required={true}
                      showLabel={true}
                      value={data.message}
                      handleInputChange={handleInputChange}
                      validateFieldChange={validateField} />

            <Checkbox validateFieldChange={validateField}
                      showLabel={true}
                      checked={data.newsletter === 'yes'} name={'newsletter'} value={"yes"}
                      label={newsletterLabel}
                      handleInputChange={handleInputChange}
            />

            <p className={'text-sm font-lora text-oblique mt-6'}>By clicking the button below, you agree to our Terms of Service and understand the Privacy Policy.</p>
            <button
                onClick={process}
                disabled={!Object.values(baseDataError).some(error => error) === false}
                className={'btn-primary w-full xl:w-1/4 mt-4 flex flex-row justify-center cursor-pointer'}>
                <span className={'text-xl'}>Subscribe</span>
                {
                    validating &&
                    spinner
                }
            </button>
        </fieldset>
    )


}

export default ContactForm