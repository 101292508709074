import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import './Consent.css'
import {useEffect} from "react";


const CookieConsentComponent = ({children}) => {

    useEffect(() => {
        CookieConsent.run({
            onChange: (param) => {
                if (!param?.cookie.categories.includes("analytics")) {
                    const regex = /^(_ga|_gid|kl_|_hp2_|_cq|_kla_id)/;
                    console.log(document.cookie)
                    // Split document.cookie string and iterate over the cookies
                    document.cookie.split(';').forEach(cookie => {
                        // Get cookie name
                        const cookieName = cookie.split('=')[0].trim();
                        console.log(cookieName)

                        // If the cookie name matches the regex, clear it
                        if (regex.test(cookieName)) {
                            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
                        }
                    });
                }
            },
            categories: {
                necessary: {
                    readOnly: true,
                    enabled: true
                },
                analytics: {
                    autoClear: {
                        cookies: [
                            {
                                name: /^(_ga|_gid|kl_|_hp2_|_cq|_kla)/
                            }
                        ]
                    }
                },
                ads: {}
            },
            language: {
                default: 'en',
                translations: {
                    en: {
                        consentModal: {
                            title: 'We use cookies',
                            description: 'Cookie modal description',
                            acceptAllBtn: 'Accept all',
                            acceptNecessaryBtn: 'Reject all',
                            showPreferencesBtn: 'Manage Individual preferences'
                        },
                        preferencesModal: {
                            title: 'Manage cookie preferences',
                            acceptAllBtn: 'Accept all',
                            acceptNecessaryBtn: 'Reject all',
                            savePreferencesBtn: 'Accept current selection',
                            closeIconLabel: 'Close modal',
                            sections: [
                                {
                                    title: 'Somebody said ... cookies?',
                                    description: 'I want one!'
                                },
                                {
                                    title: 'Strictly Necessary cookies',
                                    description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',

                                    //this field will generate a toggle linked to the 'necessary' category
                                    linkedCategory: 'necessary'
                                },
                                {
                                    title: 'Performance and Analytics',
                                    description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                                    linkedCategory: 'analytics'
                                },
                                {
                                    title: 'More information',
                                    description: 'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>'
                                }
                            ]
                        }
                    }
                }
            }
        });
    }, []);

    return (
        <>
            {children}
        </>
    )

}

export default CookieConsentComponent