import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

const SpaceContent = ({name = '', description = '', how_to_access = '', location = '',  timetables = []}) => {
    return (
        <>
            <div className={'title-container'}>
                <div className={'title'}>{name}</div>
                <p className={'flex flex-row items-center'}>
                    <FontAwesomeIcon icon={'fa-solid fa-location-dot'} className={'size-6 text-blue-950'} />
                    <span className={'text-blue-950 font-lora font-medium text-lg ml-2 text-oblique'}>{location}</span>
                </p>
            </div>

            <div className={'content'} dangerouslySetInnerHTML={{__html: description}}/>

            { timetables && (
                <>
                    <h5 className={'text-gray-800'}>Accessible</h5>
                    {how_to_access && (
                        <p className={'text-gray-500'}>{how_to_access }</p>
                    )}
                    <ul className={'timetable'}>
                        {
                            timetables.map((item, index) => (
                                <li key={item.day_of_week}><span>{item.day_of_week}</span> {item.open_time} to {item.close_time}</li>
                            ))
                        }
                    </ul>
                </>
            )}
        </>
    )
}

export default SpaceContent