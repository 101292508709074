import { configureStore } from '@reduxjs/toolkit'

import csrfReducer from './features/csrf/csrfSlice';
import { marketingApi } from './services/marketingApi';
import {spacesApi} from "./services/spacesApi";

const store = configureStore({
    reducer: {
        csrf: csrfReducer,
        [marketingApi.reducerPath]: marketingApi.reducer,
        [spacesApi.reducerPath]: spacesApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        })
            .concat(spacesApi.middleware)
            .concat(marketingApi.middleware),
});

export default store