import React, {  useState, useEffect } from 'react';
import {Routes, Route } from 'react-router-dom';
import axios from 'axios';
import Page from "../features/Layouts/Page";
import Error from "../features/Components/Error/Error";
import {useLoading} from "./LoadingContext";

// Create a context for the content

String.prototype.rtrim = function (s) {
    if (s === undefined)
        s = '\\s';
    return this.replace(new RegExp("[" + s + "]*$"), '');
};
String.prototype.ltrim = function (s) {
    if (s === undefined)
        s = '\\s';
    return this.replace(new RegExp("^[" + s + "]*"), '');
};

// ContentProvider component
const ContentProvider = ({ children }) => {
    const [routes, setRoutes] = useState([]);
    const {  setLoading } = useLoading();

    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true)
        const fetchRoutes = async () => {
            try {
                // Example: Fetch routes from multiple APIs
                const api = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/content/page`); // Replace with your API endpoints
                setRoutes(api.data.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchRoutes();
    }, [setLoading]);


    if (error) {
        throw new Error(error.message);
    }


    return (
        <Routes>
            {routes.map((story) => (
                <Route
                    key={story.id}
                    path={story.url_key === 'home' ? '/' : story.url_key}
                    element={<Page
                        seo={{"title":story.meta_title || (story.title ?? ""), "description" : story.meta_description ?? ""}}
                        classname={'page '+ (story.url_key || "").replace('/','-').ltrim('-')}
                        content={story.content} />} // Assuming contentId is fetched from the API
                />
            ))}
            {children}
        </Routes>
    );
};

export default ContentProvider;