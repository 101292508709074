import { PhoneInput ,   defaultCountries, parseCountry} from 'react-international-phone';
import {PhoneNumberUtil} from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();
export const isPhoneValid = (phone) => {

    try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
        return false;
    }
};

const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ['us', 'gb'].includes(iso2);
});


const PhoneInputWrapper = ({...rest}) => {
    
    const  {
        label = "Phone Number",
        required = false,
        name = "phone",
        ariaLabel = "phone",
        value,
        handleInputChange,
        validateFieldChange,
        error = false,
        errorMessage = `${label} is required and must be valid`
    } = rest
    return (
        <div className={'input-field group'}>
            <label htmlFor={name}>
                {label} {required && (<span className={'text-red-500'}>*</span>)}
            </label>
            <PhoneInput
                name={name}
                defaultCountry="gb"
                required={true}
                country="gb"
                id={name}
                aria-label={ariaLabel}
                value={value}
                countries={countries}
                onBlur={(e) => validateFieldChange("phone_number", e.target.value)}
                onChange={(phone) => handleInputChange({target: {name: name, value: phone}})}
            />

            {error &&
                <span className="error text-red-500 mt-1">{errorMessage}</span>}
        </div>
    )
}

export default PhoneInputWrapper