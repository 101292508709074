import './List.css'
import {useFilterSpacesMutation, useGetCategoriesQuery} from "../../../app/services/spacesApi";
import {useEffect} from "react";
import ListItem from "./ListItem";
import {useLoading} from "../../../lib/LoadingContext";
import CategoryFilter from "./CategoryFilter";
import {useSearchParams} from "react-router-dom";

const SpacesList = () => {

    const baseUrl = process.env.REACT_APP_STORAGE_URL;
    const [filterSpaces, {isFetching, data}] = useFilterSpacesMutation()
    const {data: catData, isLoading: catIsLoading} = useGetCategoriesQuery()

    const { setLoading} = useLoading();

    let [searchParams, setSearchParams] = useSearchParams();
    const spaceType = searchParams.get('spaceType')


    useEffect(() => {
        if (spaceType)
        {
            filterSpaces({"category": [spaceType]})
        }else {
            filterSpaces({})
        }

        setLoading(isFetching && catIsLoading)

    }, [spaceType]);  // eslint-disable-next-line



    const handleCatFilter = (e, name) => {

        searchParams.delete("spaceType")
        if (spaceType !== name) {
            searchParams.append("spaceType", name)
        }

        setSearchParams(searchParams);
    }

    return (
        <div className="spaces-list">
            <div className={'spaces-list__header'}>
                <CategoryFilter baseUrl={baseUrl} catData={catData} handleFilter={handleCatFilter} selected={spaceType ?? false} setLoading={setLoading} />
            </div>
            <section className="spaces-list__list container">

                {!isFetching && (
                    <>

                        {data?.result && (
                            <div className={'column'}>
                                <p className={'mb-2 text-base text-gray-500'}>{(data.result||[]).length} {(data.result||[]).length > 1 ? 'spaces': 'space'} available</p>
                                {data?.result.map((space) => (
                                    <ListItem baseUrl={baseUrl} key={space.id} space={space} />
                                ))}
                            </div>
                        )}

                        {data?.result.length < 1  && (
                            <div className={"spaces-list__no-results"}>
                                <h3 className={'title w-full justify-center items-center'}>We couldn't find any spaces.</h3>
                            </div>
                        )}
                    </>
                )}
            </section>
        </div>
    )
}

export default SpacesList

