
import './App.css';
import ContentProvider from "./lib/ContentProvider";
import React from "react";
import {Route} from "react-router-dom";
import Page from "./features/Layouts/Page";
import NotFound from "./features/Components/Error/NotFound";

import SpacesList from "./features/Components/ListSpaces/SpacesList";
import Space from "./features/Components/ViewSpaces/Space";
import ErrorBoundary from "./ErrorBoundary";
import {ModalProvider} from "./lib/Modal/ModalContext";
import {HelmetProvider} from "react-helmet-async";

function App() {
  return (
    <>
        <HelmetProvider>
            <ErrorBoundary>
                <ModalProvider>
                    <ContentProvider>
                        <Route
                            path={'/find-a-space'}
                            element={<Page><SpacesList /></Page>}
                        />

                        <Route path={"/spaces/:category?/:slug"}
                               element={<Page><Space /></Page>}
                        />

                        <Route
                            path="*"
                            element={<Page classname={'error-404'}><NotFound /></Page>}
                        />
                    </ContentProvider>
                </ModalProvider>
            </ErrorBoundary>
        </HelmetProvider>
    </>
  )
}

export default App;
