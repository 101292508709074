import {CohesiiContentComponent} from "../../lib";


const TwoColumnTextImage =({content}) => {

    const  {
        classname,
        strapline,
        additional = [],
        title,
        tag:Tag,
        asset,
        asset_title,
        asset_alt,
        "image-position":image_position,
        content:html ,
        style} = content

    const imageData = {
        data: {asset: asset, asset_title: asset_title, asset_alt: asset_alt },
        type: 'asset'
    }

    const renderedClass = `${classname || ''} image-${image_position || 'start'} style-${style || ''}`.trim()
    return (
        <section className={`two-column-image-text ${renderedClass}`.trim()}>
            <div className={'container row'}>
                <div className={'column justify-center'}>
                    <div className={'title-container'}>
                        <p className={'strapline'}>{strapline}</p>
                        <Tag className={'title'}>{title}</Tag>
                    </div>
                    <div className={'content'} dangerouslySetInnerHTML={{__html: html}}/>

                    {additional.length > 0 && (
                        <div className={'additional column'}>
                            {
                                additional.map((nested, index) => (
                                    <CohesiiContentComponent key={nested.data._uid || index} content={nested} />
                                ))
                            }
                        </div>
                    )}
                </div>
                <div className={'column'}>
                    <CohesiiContentComponent content={imageData} />
                </div>
            </div>
        </section>
    )


}

export default TwoColumnTextImage