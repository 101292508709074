import React, {forwardRef, useCallback, useEffect, useRef, useState} from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { ChevronUpIcon } from '@heroicons/react/24/solid'
import {Link} from "react-router-dom";

export const ResourcesMegaMenu = forwardRef(({ isHidden, isOpen, onToggle }, ref) => {

    const menuRef = useRef(null);

    const handleClickOutside = useCallback((event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            if (isOpen) {
                onToggle();
            }
        }
    }, [menuRef, onToggle]);

    useEffect(() => {
        if (isHidden && isOpen) {
            onToggle();
        }
    }, [isHidden, isOpen, onToggle]);



    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onToggle();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
            <li  ref={menuRef}>
                <span
                    className="group cursor-pointer"
                    onClick={onToggle}
                    onKeyDown={handleKeyDown}
                    tabIndex={0}
                    role="button"
                    aria-expanded={isOpen}
                >
                    Resources
                    {!isOpen && <ChevronDownIcon className="size-4 text-blue-950 group-hover:text-blaze-500"/>}
                    {isOpen && <ChevronUpIcon className="size-4 text-blue-950 group-hover:text-blaze-500"/>}
                </span>

                {isOpen && (
                    <div className="absolute left-0 top-full w-full bg-white shadow-lg z-10">
                        <div className="container mx-auto p-4">
                            <div className="grid grid-cols-6 gap-4 py-4">
                                <div className={'mega-menu__column col-span-2'}>
                                    <div>
                                        <h3 className={'title !text-lg'}>Cohesii</h3>
                                        <div className={'row flex-wrap !gap-0 mt-2'}>
                                            <Link to={'/about-us'} title={'About Cohesii'}
                                                  className={'link min-w-[45%]'}>
                                                About Us
                                            </Link>
                                            <Link to={'/our-mission'} title={'Our Mission'}
                                                  className={'link min-w-[45%]'}>
                                                Our Mission
                                            </Link>
                                            <Link to={'/resources/blog'} title={'Blog'} className={'link min-w-[45%]'}>
                                                Blog
                                            </Link>
                                            <Link to={'/contact'} title={'Contact Us'} className={'link min-w-[45%]'}>
                                                Contact Us
                                            </Link>

                                        </div>
                                    </div>
                                    <div className={'mt-4'}>
                                        <h3 className={'title !text-lg'}>More about business</h3>
                                        <div className={'row flex-wrap !gap-0 mt-2'}>
                                            <Link to={'/about-us'} title={'About Cohesii'}
                                                  className={'link min-w-[45%]'}>
                                                About Us
                                            </Link>
                                            <Link to={'/resources/blog'} title={'Blog'} className={'link min-w-[45%]'}>
                                                Blog
                                            </Link>
                                            <Link to={'/contact'} title={'Contact Us'} className={'link min-w-[45%]'}>
                                                Contact Us
                                            </Link>

                                        </div>
                                    </div>

                                </div>
                                <div className={'mega-menu__column col-span-4'}>
                                    <h3 className={'title !text-lg'}>Latest Blogs</h3>

                                </div>

                            </div>
                        </div>
                    </div>
                )}
            </li>
    );
});

