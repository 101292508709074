import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CohesiiContentComponent} from "../../lib";

export const IconGroup = ({content}) => {

    const {blocks, title, strapline, tag:Tag} = content
    return (
        <section className={'icon-group'}>
            {(title || strapline) && (
                <div className={'title-container'}>
                    {strapline && (<p className={'strapline'}>{strapline}</p>)}
                    {title && (<Tag className={'title'}>{title}</Tag>)}
                </div>
            )}

            <div className={'icon-group__icons'}>
                {
                blocks.map((nested, index) => (
                    <CohesiiContentComponent key={nested.data._uid || index} content={nested} />
                ))
            }
            </div>
        </section>
    )
}

const Icon = ({content}) => {

    const {icon, background, title, tag:Tag, content: html, "icon-position": position, bordered = 'no'} = content;

    const hasBackground = background === 'no' ? '': `bg-${background}`
    const iconCode = `fa-solid ${icon.replace('fas-', 'fa-')}`;
    const border = bordered === 'yes' ? 'border' :'';

    if (position === 'icon-only') {
        return  (
            <div className={`icon ${border} ${hasBackground}`.trim()}>
                <FontAwesomeIcon icon={iconCode}  className={'text-blue-950 size-8'} />
            </div>
        )
    }

    return (
        <div className={`icon ${border} ${hasBackground}`.trim()}>
            <div className={`icon-container ${position}`}>
                <FontAwesomeIcon icon={iconCode} className={'text-blue-950 size-5'} />
                <Tag className={'icon-title'}>{title}</Tag>
            </div>
            {
                html && <div className={'content'} dangerouslySetInnerHTML={{__html: html}}/>
            }
        </div>
    )

}

export  default  Icon