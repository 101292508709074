import {forwardRef, useState} from "react";
import {Link} from 'react-router-dom'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import {SolutionsMegaMenu} from "./SolutionsMenu";
import {ResourcesMegaMenu} from "./ResourcesMenu";



const Menu = forwardRef(({isHidden}, ref) => {

    const [openMenu, setOpenMenu] = useState(null);

    const handleMenuToggle = (menuName) => {
        setOpenMenu(openMenu === menuName ? null : menuName);
    };

    return (
        <nav className={'main-menu '}>
            <ul>
                <li>
                    <Link to={'/find-a-space'} title={'Find a space'} className={'group'}>
                        Find a space
                    </Link>
                </li>
                <SolutionsMegaMenu
                    isHidden={isHidden}
                    ref={ref}
                    isOpen={openMenu === 'solutions'}
                    onToggle={() => handleMenuToggle('solutions')}
                />
                <ResourcesMegaMenu
                    isHidden={isHidden}
                    ref={ref}
                    isOpen={openMenu === 'resources'}
                    onToggle={() => handleMenuToggle('resources')}
                />


            </ul>

        </nav>
    )


})

export default Menu