import 'react-international-phone/style.css';
import {useState} from "react";
import NewsletterImage from './newsletter.png'
import NewsletterForm from "./Form";


const Newsletter = () =>
{
    const [formComplete, setFormComplete] = useState(false)

    return (
        <section className={'newsletter bg-blaze-50'}>
            <div className={'container flex flex-col lg:flex-row'}>
                <div className={'column justify-center'}>
                    <div className="title-container">
                        <h5 className="title !text-4xl">Lets stay in touch</h5>
                        <p className={'content text-[1.4rem] mb-3 text-gray-500'}>
                            Get the latest spaces emailed and text to you when they become available, or keep up to date with out latest news and updates.
                        </p>
                    </div>

                    {!formComplete && <NewsletterForm formComplete={formComplete} setFormComplete={setFormComplete} />}
                    {formComplete && (
                        <p className={'text-blue-950'}>Thanks for subscribing to our newsletter lists, you should shortly receive an email to confirm your participation.</p>
                    )}
                </div>
                <div className={'flex-col hidden lg:flex'}>
                    <figure className={'flex w-full flex-1 justify-end'}>
                        <img src={NewsletterImage} title={'Newsletter'} alt={'PC on a desk'} className={'rounded-[15px] w-3/4'}/>
                        <figcaption className={'sr-only'}>PC on a desk</figcaption>
                    </figure>
                </div>
            </div>
        </section>
    )


}

export default Newsletter