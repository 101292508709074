const Code = ({content}) => {

    const {html} = content

    console.log(content)

    return (
        <div className={'code content'} dangerouslySetInnerHTML={{__html:html}} />
    )
}

export default Code