import {useParams} from "react-router-dom";
import React, {useEffect, useRef} from "react";
import {useGetSpaceQuery} from "../../../app/services/spacesApi";
import {NotFoundError} from "../../../ErrorBoundary";
import {useLoading} from "../../../lib/LoadingContext";
import MediaGallery from "./Gallery";
import MapView from "./Map";
import Facilities from "./Facilities";
import './Space.css'
import SpaceContent from "./SpaceContent";
import {useModal} from "../../../lib/Modal/ModalContext";
import GallerySlider from "./GallerySlider";
import Solutions from "./Solutions/Solutions";

const Space = () => {

    const galleryRef = useRef(null);
    const facilityRef = useRef(null);
    const { showModal } = useModal();

    const baseUrl = process.env.REACT_APP_STORAGE_URL;
    const {slug = ''} = useParams()

    const {data, error, isFetching} = useGetSpaceQuery(slug)


    const {loading, setLoading} = useLoading();

    const {
        solutions = {},
        attributes = {},
        facilities={},
        timetables={},
        name,
        location
    } = data || {}

    useEffect(() => {

        setLoading(true)
        if (!isFetching) {
            if (error && error['status'] === 404) {
                throw new NotFoundError("Not found")
            }

            setLoading(false)
        }

    }, [data, error, isFetching, setLoading]);


    const handleOpenModal = (index = 0) => {
        const component = <GallerySlider  baseUrl={baseUrl} images={data.media} startingIndex={index} />
        showModal(component);
    };

    const scrollToGal = () => {
        if (galleryRef.current) {
            galleryRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };


    return (
        <>
            {!loading && data && (
            <div className={'space'}>
                <div className={'space__header'}>
                    <img loading={"lazy"} title={name} src={`${baseUrl}${data.media[0]}`} className={'w-full max-h-[200px] md:max-h-[400px] object-cover object-center'}  alt={name}/>
                </div>
                <div className={'space__body'}>
                    <div className={'row container'}>
                        <div className={'column py-[50px] min-w-[60%]'}>
                            <div className={'gallery relative'}>
                                <button onClick={scrollToGal} className={'btn-primary absolute top-[20px] left-[20px]'}>
                                    See full gallery
                                </button>
                                <div className={'row'}>
                                    <div className={'column flex-1'}>
                                        <img title={name} src={`${baseUrl}${data.media[0]}`}
                                             className={'min-h-full  object-cover object-center rounded-md'}
                                             alt={name}/>
                                    </div>
                                    <div className={'column !gap-[20px]'}>
                                        <img title={name} src={`${baseUrl}${data.media[1]}`}
                                             className={'w-full max-h-[150px]  object-cover object-center rounded-md'}
                                             alt={data.name}/>
                                        <img title={name} src={`${baseUrl}${data.media[2]}`}
                                             className={'w-full max-h-[150px]  object-cover object-center rounded-md'}
                                             alt={name}/>
                                    </div>
                                </div>
                            </div>

                            <SpaceContent name={name}
                                          description={attributes.description}
                                          location={location}
                                          timetables={timetables}
                                          how_to_access={attributes.how_to_access}
                            />


                            <Facilities  facilities={facilities} facilityRef={facilityRef} />

                            <MediaGallery onClick={handleOpenModal} data={data} baseUrl={baseUrl} blockRef={galleryRef} />


                        </div>
                        <div className={'column'}>
                            <Solutions solutions={solutions} />
                        </div>
                    </div>
                </div>
                <div className={'space__footer'}>
                    <MapView data={data} />
                </div>
            </div>
        )}
        </>
    )
}

export default Space