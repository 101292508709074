import React, {forwardRef, useCallback, useEffect, useRef, useState} from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { ChevronUpIcon } from '@heroicons/react/24/solid'
import {Link} from "react-router-dom";

export const SolutionsMegaMenu = forwardRef(({ isHidden, isOpen, onToggle }, ref) => {

    const menuRef = useRef(null);

    const handleClickOutside = useCallback((event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            if (isOpen) {
                onToggle();
            }
        }
    }, [menuRef, onToggle]);

    useEffect(() => {
        if (isHidden && isOpen) {
            onToggle();
        }
    }, [isHidden, isOpen, onToggle]);


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onToggle();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
            <li  ref={menuRef}>
                <span
                    className="group cursor-pointer"
                    onClick={onToggle}
                    onKeyDown={handleKeyDown}
                    tabIndex={0}
                    role="button"
                    aria-expanded={isOpen}
                >
                    Our Solutions
                    {!isOpen && <ChevronDownIcon className="size-4 text-blue-950 group-hover:text-blaze-500"/>}
                    {isOpen && <ChevronUpIcon className="size-4 text-blue-950 group-hover:text-blaze-500"/>}
                </span>

                {isOpen && (
                    <div className="absolute left-0 top-full w-full bg-white shadow-lg z-10">
                        <div className="container mx-auto p-4">
                            <div className="grid grid-cols-3 gap-4 py-4">
                                <div className={'mega-menu__column'}>
                                    <h3 className={'title !text-lg'}>Office Space</h3>
                                    <Link to={'/solutions/office/on-demand'} title={'On demand office space'}>
                                        <p className={'link'}>On Demand offices</p>
                                        <p>Pay by the hour office space for those short term collaborative working sessions</p>
                                    </Link>

                                    <Link to={'/solutions/office/short-term'} title={'Short term office space'}>
                                        <p className={'link'}>Short term office space</p>
                                        <p>With a need to test back to office or hybrid space solutions for 3 months</p>
                                    </Link>

                                    <Link to={'/solutions/office/long-term'} title={'Long term office space'}>
                                        <p className={'link'}>Long term offices</p>
                                        <p>Looking to move to a new office, or get your startup in somewhere concrete?</p>
                                    </Link>
                                </div>
                                <div className={'mega-menu__column'}>
                                    <h3 className={'title !text-lg'}>Specialty Space</h3>
                                    <Link to={'/solutions/specialty/studio'} title={'Studio Spaces'}>
                                        <p className={'link'}>Studio Spaces</p>
                                        <p>Mix some music or take a clients product photos by the hour</p>
                                    </Link>

                                    <Link to={'/solutions/specialty/venues'} title={'Venue Spaces'}>
                                         <p className={'link'}>Venue Spaces</p>
                                        <p>Congratulations, its time to party or get married, we have your venue covered</p>
                                    </Link>

                                    <Link to={'/solutions/specialty/meeting-rooms'} title={'Meeting Rooms'}>
                                        <p className={'link'}>Meeting Rooms</p>
                                        <p>Run a remote business but have a client meeting, check out our meeting rooms.</p>
                                    </Link>
                                </div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                )}
            </li>
    );
});

