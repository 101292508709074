// src/services/api.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL+'/spaces/',
    credentials: 'include', // Important to include cookies
});

export const spacesApi = createApi({
    reducerPath: 'spacesApi',
    baseQuery,
    tagTypes: ['Spaces', 'Space', 'SpaceCategories'],
    endpoints: (builder) => ({
        filterSpaces: builder.mutation({
            query: (body) => ({
                url: `filterSpaces`,
                method: 'POST',
                body: body,
            }),
            transformResponse: (response) => response.data,
            providesTags: (result, error, body) => [{ type: 'Spaces', body }],
        }),
        getSpace: builder.query({
            query: (slug) => `space/${slug}`,
            transformResponse: (response) => response.data,
            providesTags: (result, error, slug) =>
                [{ type: 'Space', slug }]
        }),
        getCategories: builder.query({
            query: (slug) => `categories`,
            transformResponse: (response) => response.data,
            providesTags: (result, error, slug) =>
                [{ type: 'SpaceCategories', slug }]
        }),
    }),
});

export const { useGetSpaceQuery, useGetCategoriesQuery,  useFilterSpacesMutation } = spacesApi;