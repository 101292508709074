import {CohesiiContentComponent} from "../../lib";
import {useRef, useState} from "react";
import {FooterComponent, HeaderComponent} from "../Components";
import Newsletter from "../Components/Newsletter/Newsletter";
import {Helmet} from "react-helmet-async";


const Page = ({content = null ,classname = 'cms-page',seo, children}) => {

    const pageRef = useRef(null)

    const robots = process.env.REACT_APP_ROBOTS

    return (
        <>
            <Helmet>
                <title>Cohesii | {seo?.title ?? ""} </title>
                <meta name='description' content={(seo?.description ?? null) || "Cohesii Spaces that fit your life"} />
                <meta name="robots" content={robots}/>
            </Helmet>

            <HeaderComponent />
            <main className={classname}>
                    <div className={'wrapper'}>
                    {
                        content &&
                            content.map((nested, index) => (
                                <CohesiiContentComponent ref={pageRef} key={nested.data._uid || index} content={nested} />
                            ))
                    }
                    {children}
                </div>
                <Newsletter />
            </main>
            <FooterComponent />
        </>
    )
}

export default Page