import ContactForm from "./ContactForm";
import {CohesiiContentComponent} from "../../../lib";

const ContactPage = ({content}) => {

    const {
        form_strapline = '',
        form_title = '',
        form_tag: FormTag = '',
        content_tag: ContentTag = '',
        contact_title = '',
        top_content = '',
        Icons = '',
        bottom_content = '',
        _uid = '',
        classname = ''
    } = content || {}


    return (
        <section id={_uid || ''} className={`contact ${classname || ''}`.trim()}>
            <div className={'container row reverse-mob'}>
                <div className={'column md:pr-8 md:border-r-[1px] border-gray-200'}>

                    <div className={'title-container'}>
                        <p className={'strapline'}>{form_strapline}</p>
                        <FormTag className={'title'}>{form_title}</FormTag>
                    </div>

                    <div className={"content"} dangerouslySetInnerHTML={{__html: top_content}}/>

                    <div className={'flex flex-col pl-4 gap-4'}>
                    {
                        Icons.map((nested, index) => (
                            <CohesiiContentComponent key={nested.data._uid || index} content={nested} />
                        ))
                    }
                    </div>

                    <div className={"content prose-p:italic prose-p:text-sm"} dangerouslySetInnerHTML={{__html: bottom_content}}/>


                    <div className={'column !gap-2 mt-6'}>
                        <h4 className={'text-blue-950 text-lg font-medium text-oblique font-lora'}>Call Us</h4>
                        <p className={'text-gray-500'}>
                            Questions about plans, pricing or availability? Just have your people call our people.
                        </p>
                    </div>

                </div>
                <div className={'column md:pl-8'}>
                    <div className={'title-container'}>
                        <ContentTag className={'title'}>{contact_title}</ContentTag>
                    </div>
                    <p className={'text-gray-500 text-lg'}>
                        Complete the form below and a member of our team will get in contact to learn more about your workplace needs.
                    </p>
                    <ContactForm/>
                </div>
            </div>
        </section>
    )


}

export default ContactPage