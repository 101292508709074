import {CohesiiContentComponent} from "../../lib";

const Section = ({content}) => {

    const {blocks, classname, _uid} = content;

    return (
        <section id={`${_uid || ""} `} className={`container ${classname || ""}`.trim()}>
            <div className={'column'}>
                {
                    blocks.map((nested, index) => (
                        <CohesiiContentComponent key={nested.data._uid || index} content={nested} />
                    ))
                }
            </div>
        </section>
    )
}

export default Section