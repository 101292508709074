import {useState} from "react";
import TextInput from "../../Form/TextInput";
import PhoneInputWrapper, {isPhoneValid} from "../../Form/PhoneInput";
import {useSubscribeMutation} from "../../../app/services/marketingApi";






export const spinner = (
    <div
        className="inline-block h-5 w-5   self-center animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
        role="status">
              <span
                  className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
              >Loading...</span
              >
    </div>
)

const NewsletterForm = ({formComplete, setFormComplete}) => {

    const [subscribe, {isLoading}] = useSubscribeMutation()

    const [validating, setValidating] = useState(false)
    const [data, setData] = useState(
        {
            'email' : '',
            'phone_number': '',
            'types': [28, 27],
            'honeypot': '',

        }
    )
    const [baseDataError, setBaseDataErrors] = useState({
        'email': false,
        'phone_number': false,
        'honeypot': false,
        'types': false
    });

    const isValidPhoneNumber = isPhoneValid(data?.phone_number || '');

    const updateArray = (currentArray, value) => {
        if (currentArray.includes(value)) {
            // Remove the value from the array
            return currentArray.filter((item) => item !== value);
        } else {
            // Add the value to the array
            return [...currentArray,  value];
        }
    };

    /**
     * Global handleinput
     * @param e
     */
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setData((prevData) => {
            const currentValue = prevData[name];
            if (Array.isArray(currentValue)) {
                // Use the utility function to update the array
                return {
                    ...prevData,
                    [name]: updateArray(currentValue, value),
                };
            } else {
                // Handle non-array values normally
                return {
                    ...prevData,
                    [name]: value,
                };
            }
        });
    };

    /**
     * Validate individual fields
     * @param fieldname
     * @param value
     * @returns {boolean}
     */
    const validateField = (fieldname, value = '') => {
        let error = false
        switch (fieldname) {
            case 'phone_number':
                error = !isValidPhoneNumber
                break;
            case 'email':
                error = !value || !/\S+@\S+\.\S+/.test(value);
                break;
            default:
                break;
        }

        setBaseDataErrors(prevErrors => ({ ...prevErrors, [fieldname]: error }));
        return error;
    }

    const process = () => {
        setValidating(true)
        const errors = {
            email: validateField('email', data.email),
            phone_number: validateField('phone_number', data.phone_number),
            honeypot: validateField('honeypot', data.honeypot),
            types: validateField('types', data.types),
        };

        if(!Object.values(errors).some(error => error)) {
            // process the form here using redux rtk
            setValidating(false)

            subscribe(data)
            setFormComplete(true)
        }else {
            setValidating(false)
        }

    }

    return (
        <fieldset className={'flex flex-col '}>
            <legend className={'sr-only'}>Newsletter Signup</legend>
            <TextInput
                handleInputChange={handleInputChange}
                validateFieldChange={validateField}
                showLabel={true}
                value={data.email || ""}
                error={baseDataError.email}
                name={'email'} type={'email'} placeholder={'test@test.com'} label={'Email'}/>

            <PhoneInputWrapper handleInputChange={handleInputChange}
                               validateFieldChange={validateField}
                               showLabel={true}
                               name={"phone_number"}
                               id={"phone_number"}
                               value={data.phone_number || ""}
                               ariaLabel={"phone number"}
                               error={baseDataError.phone_number}/>


            <button
                onClick={process}
                disabled={!Object.values(baseDataError).some(error => error) === false}
                className={'btn-primary w-full xl:w-1/4 mt-4 flex flex-row justify-center cursor-pointer'}>
                <span className={'text-xl mr-2'}>Subscribe</span>
                {
                    validating &&
                    spinner
                }
            </button>
        </fieldset>
    )

}


export default NewsletterForm