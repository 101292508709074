import { Link } from "react-router-dom";
import * as CookieConsent from "vanilla-cookieconsent";
import './Footer.css'
import LogoWhite from '../../../assets/cohesii-white.svg'
const Footer = () => {

    return (
        <footer id={'footer'}>
            <div className={'container mx-auto'}>
                <div className={'row'}>
                    <section className={'business'}>
                        <img src={LogoWhite} className={'not-prose'} title={'Cohesii'} alt={'White alternative logo for Cohesii'} />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eu fermentum leo.</p>
                    </section>
                    <section className={'sitemap'}>
                        <div className={'column'}>
                            <h4>Services</h4>
                            <ul className={'menu'}>
                                <li>
                                    <Link to={'/solutions/office/on-demand'} title={'On Demand Offices'}>
                                        On Demand Offices
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/solutions/office/short-term'} title={'Short Term Offices'}>
                                        Short Term Office Space
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/solutions/office/long-term'} title={'Long term offices'}>
                                        Long Term Office Space
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/solutions/specialty/studio'} title={'Studio Space'}>
                                        Studio Spaces
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/solutions/specialty/meeting-rooms'} title={'Meeting Rooms'}>
                                        Meeting Rooms
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/solutions/specialty/venue'} title={'Venue Spaces'}>
                                        Venue Spaces
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className={'column'}>
                            <h4>Resources</h4>
                            <ul className={'menu'}>
                                <li>
                                    <Link to={'/about-us'} title={'About Cohesii'}>
                                        About us
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/blog/news'} title={'News'}>
                                        News
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/blog'} title={'Blog'}>
                                        Blog
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/investment'} title={'Investment'}>
                                        Investment
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/blog/support'} title={'Support'}>
                                        Support
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/contact'} title={'Contact'}>
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className={'column'}>
                            <h4>Social</h4>
                            <ul className={'menu'}>
                                <li>
                                    <Link to={'#'} title={'Facebook'} target={'_blank'}>
                                        Facebook
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'#'} title={'Linkedin'} target={'_blank'}>
                                        Linkedin
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'#'} title={'Instagram'} target={'_blank'}>
                                        Instagram
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'#'} title={'X formerly Twitter'} target={'_blank'}>
                                        X formerly Twitter
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'#'} title={'Product Hunt'} target={'_blank'}>
                                        Product Hunt
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className={'column'}>
                            <h4>Legal</h4>

                            <ul className={'menu'}>
                                <li>
                                    <Link to={'/terms-and-conditions'} title={'Terms and Conditions'}>
                                        Terms and Conditions
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/privacy-policy'} title={'Privacy and GDPR Policy'}>
                                        Privacy and GDPR Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/anti-slavery-policy'} title={'Anti Slavery Policy'}>
                                    Anti Slavery Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/security-and-accessibility'} title={'Accessibility And Security'}>
                                        Security and accessibility
                                    </Link>
                                </li>
                                <li>
                                    <a href={'#'} onClick={(e) => { e.preventDefault(); CookieConsent.showPreferences()}}>
                                        Cookie Preferences
                                    </a>
                                </li>
                            </ul>

                        </div>
                    </section>
                </div>
                <div className={'row'}>
                    <p className={'flex-1 text-sm'}>© Copyright 2024-All Cohesii ltd</p>
                </div>
            </div>
        </footer>
)
}

export default Footer