import React from 'react';
import NotFound from "./features/Components/Error/NotFound";
import ErrorPage from "./features/Components/Error/Error";
import Page from "./features/Layouts/Page";
import {Helmet} from "react-helmet-async";

export class NotFoundError extends Error {
    constructor(message, status = 404) {
        super(message);
        this.name = "NotFoundError";
        this.status = status;
    }
}

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, status: 500 };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true, status: error.status || 500});
        console.log(error)
        // log the error or perform any necessary actions
    }

    render() {
        if (this.state.hasError) {
            // fallback UI
            if ((this.state.status || 500) === 404)
            {
                return (
                    <Page classname={'error-404'}>
                        <Helmet>
                            <title>Cohesii || 404 Not found</title>
                        </Helmet>
                        <NotFound />
                    </Page>
                )
            }
            return <Page classname={'error-500'}>
                <Helmet>
                    <title>Cohesii || 500 We found an error</title>
                </Helmet>
                <ErrorPage />
            </Page>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;