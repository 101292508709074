import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import store from './app/store'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import {contentBuilderInit} from "./lib";

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

import Page from "./features/Layouts/Page";
import {
    BannerComponent,
    ButtonComponent,
    ColumnComponent,
    ImageComponent,
    RowComponent,
    SectionComponent,
    TitleComponent,
    TwoColumnTextImageComponent,
    ImageCtaComponent,
    IconComponent,
    BannerWithContentComponent,
    FaqsComponent, FaqComponent, CodeComponent, IconGroupComponent
} from "./features/Simple";


import { fetchCsrfToken } from './app/features/csrf/csrfSlice';
import ContactPage from "./features/Components/Contact/ContactPage";
import CookieConsentComponent from "./lib/CookieConsent";
import {LoadingProvider} from "./lib/LoadingContext";
import {APIProvider} from "@vis.gl/react-google-maps";

library.add(fab, fas)

contentBuilderInit({
    apiKey: 'Null',
    types: {
       'page' : Page
    },
    components: {
        'section' : SectionComponent,
        'banner': BannerComponent,
        'row': RowComponent,
        'column': ColumnComponent,
        'image': ImageComponent,
        'asset': ImageComponent,
        'title': TitleComponent,
        'button' : ButtonComponent,
        '2-column-text-image' : TwoColumnTextImageComponent,
        'image_cta' : ImageCtaComponent,
        'contact_form' : ContactPage,
        'icon-group-icon': IconComponent,
        'banner_content_area': BannerWithContentComponent,
        'faqs': FaqsComponent,
        'faq': FaqComponent,
        'code': CodeComponent,
        'icon-group': IconGroupComponent,
    }
})

store.dispatch(fetchCsrfToken());

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

      <LoadingProvider>
          <APIProvider apiKey={"AIzaSyAtBe919tk4J1owu_W49rsdE1DqXmuXQgc"}>
              <Provider store={store}>
                      <CookieConsentComponent>
                          <BrowserRouter>
                              <App />
                          </BrowserRouter>
                      </CookieConsentComponent>
              </Provider>
          </APIProvider>
      </LoadingProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
